import { Helmet } from 'react-helmet-async';
import { useAppTheme } from 'App';
import { PropsWithChildren } from 'react';

interface Props {
  subtitle?: string;
}
export function CustomHelmet({ children, subtitle }: PropsWithChildren<Props>) {
  const theme = useAppTheme();

  if (!theme) return null;

  return (
    <Helmet>
      <title>
        {theme.name}
        {subtitle ? ` - ${subtitle}` : ''}
      </title>
      {children}
    </Helmet>
  );
}
