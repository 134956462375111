import { PlusCircleIcon } from 'components/icons';
import { HTMLAttributes } from 'react';

export function AddButton(props?: HTMLAttributes<HTMLButtonElement>) {
  let cn = '';

  if (props?.className) {
    cn = props.className;
  }

  return (
    <button type={'button'} {...props} className={'add-button text-primary ' + cn}>
      <PlusCircleIcon />
    </button>
  );
}
