import { Stack } from 'react-bootstrap';
import { QuestionMark } from 'components/question-mark';
import { CustomIcon } from 'components/icons';
import { ReactNode } from 'react';
import { AddButton } from 'components/add-button';
import { AddLink } from 'components/add-link';
import { To } from 'react-router-dom';

import styles from './style.module.scss';

interface BaseProps {
  title: ReactNode;
  icon?: CustomIcon;
  questionMarkText?: ReactNode;
}
type ConditionalProps =
  | {
      onClickAddButton?: () => void;
      to?: never;
    }
  | {
      to?: To;
      onClickAddButton?: never;
    };
type Props = BaseProps & ConditionalProps;
export function SessionTitle(props: Props) {
  const { title, icon, questionMarkText, to, onClickAddButton } = props;

  return (
    <>
      <Stack direction="horizontal" className={`position-absolute ps-5 ms-3 p-4 d-md-none ${styles.sessionTitle}`} gap={2}>
        {typeof title === 'string' ? <h2 className="fw-bold text-white">{title}</h2> : title}
        {to && <AddLink className="fw-bold text-white" to={to} />}
      </Stack>

      <div className="text-primary d-none d-md-flex gap-2 align-items-center">
        {icon}
        <h2 className="fw-bold text-primary">{title}</h2>
        {onClickAddButton && <AddButton onClick={onClickAddButton} />}
        {to && <AddLink to={to} />}
        {questionMarkText && <QuestionMark text={questionMarkText} />}
      </div>
    </>
  );
}
