import { PropsWithChildren, ReactNode, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/types';
import clsx from 'clsx';

import style from './style.module.scss';

type Props = {
  renderTooltip?: boolean;
  text: string | ReactNode;
  danger?: boolean;
  placement?: Placement | undefined;
  containerClassName?: string;
  invertColors?: boolean;
  defaultShow?: boolean;
  fontSize?: 'fs-1' | 'fs-2' | 'fs-3' | 'fs-4' | 'fs-5';
  selectable?: boolean;
  hideOnMobile?: boolean;
};
export type TooltipWarningProps = Omit<Props, 'children'>;
export default function TooltipWarning(props: PropsWithChildren<Props>) {
  const {
    renderTooltip = true,
    selectable = true,
    fontSize = 'fs-5',
    text,
    children,
    danger = false,
    placement = 'bottom',
    containerClassName = '',
    defaultShow = false,
    invertColors,
    hideOnMobile = true
  } = props;
  const [show, setShow] = useState(defaultShow);

  if (!renderTooltip) return <>{children}</>;

  let content: typeof text;
  if (typeof text === 'string') {
    if (text.trim().length > 0) {
      content = <p className="text-white m-0 text-center">{text}</p>;
    }
  } else if (Array.isArray(text)) {
    if (text.length > 0) {
      content = text.map((t, i) => (
        <p key={i} className="text-white m-0 text-center">
          {t}
        </p>
      ));
    }
  } else content = text;

  const enter = () => setShow(content != null);
  const leave = () => setShow(false);

  return (
    <OverlayTrigger
      show={show}
      placement={placement}
      offset={[0, 2]}
      overlay={props => (
        <Tooltip
          id={`tooltip`}
          {...props}
          className={clsx(`${fontSize} ${style.tooltip}`, { danger, [style.invertColors]: invertColors }, { [style.unselectable]: !selectable })}
          onMouseEnter={enter}
          onMouseLeave={leave}
        >
          {content}
        </Tooltip>
      )}
    >
      <div onMouseEnter={enter} onMouseLeave={leave} className={clsx(containerClassName, { 'd-none d-xl-block': hideOnMobile })}>
        {children}
      </div>
    </OverlayTrigger>
  );
}
