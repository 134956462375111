import { QueryClient } from '@tanstack/react-query';
import { whichDomain } from 'helpers/which-domain';

export const appURLs = () => {
  // @ts-ignore
  const apiURL = process.env.REACT_APP_API_URL.replace('rosterelf.net', `${whichDomain()}.net`) + '/' + process.env.REACT_APP_API_VERSION;
  // @ts-ignore
  const publicURL = process.env.REACT_APP_PUBLIC_URL.replace('rosterelf.net', `${whichDomain()}.net`);
  // @ts-ignore
  const baseApp = process.env.REACT_APP_BASE_APP.replace('rosterelf.net', `${whichDomain()}.net`);
  // @ts-ignore
  const uploadURL = process.env.REACT_APP_UPLOAD_URL.replace('rosterelf.net', `${whichDomain()}.net`);
  // @ts-ignore
  const prefix = process.env.REACT_APP_API_PREFIX;

  return { apiURL, publicURL, baseApp, uploadURL, prefix };
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000
      // select(response: any) {
      //   return response.data;
      // }
    }
  }
});
