import { PAYROLL_CYCLE, PAYROLL_FORMAT } from './payroll';
import { Layout } from 'react-grid-layout';
import { ReportSlug } from 'pages/reports';

export type UserType = {
  accountType: string;
  sid: string;
  userInfo: UserInfoType;
};

export enum PERMISSION {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  MANAGER_ASSISTANT = 'MANAGER_ASSISTANT',
  STAFF = 'STAFF',
  ACCOUNTANT = 'ACCOUNTANT'
}

export enum USER_STATUS {
  YET_TO_LOGIN = 'YET_TO_LOGIN',
  MISSING_PAY_TEMPLATE = 'MISSING_PAY_TEMPLATE',
  NOT_SYNCED_TO_XERO = 'NOT_SYNCED_TO_XERO',
  SALARY_EMPLOYEE = 'SALARY_EMPLOYEE'
}

export enum SUBSCRIPTION_NAME {
  PRO = 'PRO',
  BASIC = 'BASIC',
  FLEX = 'FLEX'
}
export type UserInfoType = {
  accountFirstName: string;
  accountId: number;
  accountLastName: string;
  automaticalyTopUp: string;
  avatar: string;
  authid: string;
  PIN: string | null;
  billingExpiryDays: number;
  canSeeBanners: Boolean;
  canSeeBannersOnMobile: Boolean;
  canSeeBannersOnTablet: Boolean;
  dateOfBirth: string | null;
  email: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  mobileNationalNumber: string;
  mobileNumberCountryCode: string;
  payrollType: PAYROLL_FORMAT;
  permission: PERMISSION;
  subscriptionName: SUBSCRIPTION_NAME;
  priceTable: number;
  rosterCredits: string;
  shiftSwapHours: string | null;
  siteName: string;
  smartPhone: Boolean;
  staffViewOnApp: Boolean;
  tablet: Boolean;
  trialDays: number;
  uid: number;
  pushNotificationSettings: PushNotificationSettingsType;
  showPaywall: boolean;
  dashboardWidgets: DashboardWidgets | null;
  mfaEnabled: boolean;
  mfaForced: boolean;
  isBillingSaved: Boolean;
  isCreditCardExpired: Boolean;
  isFirstLogin: Boolean;
  isGeneralManager: Boolean;
  isNotifyUpdateCredit: Boolean;
  isShiftSwapEnabled: Boolean;
  isSmartPhoneGPSEnabled: Boolean;
  isTabletEnabled: Boolean;
  isTrialExp: Boolean;
  isSpecialSubscription: boolean;
  isXeroUser: boolean;
  isOnboardSendInvite: boolean;
  rosterCycle: string;
  rosterFirstDay: string;
  payrollCycle: PAYROLL_CYCLE;
  sliderInterval: number;
  isSyncCalendarShifts: boolean;
  isSyncCalendarLeavePending: boolean;
  isSyncCalendarLeaveConfirmed: boolean;
  isGoogleCalendarAuthorised: boolean;
  isMicrosoftCalendarAuthorised: boolean;
  syncedGoogleAccount?: string;
  syncedMicrosoftAccount?: string;
  reportFavorites: Partial<Record<ReportSlug, boolean>>;
  otherAccounts: {
    authid: string;
    id: number;
    siteId: number;
    siteName: string;
  }[];
  exportConfig: ExportConfig | null;
  newRoster: boolean;
  hasRosterPublished: boolean;
  scope?: string;
  unscheduledShiftsEnabled: 'NONE' | 'ALL' | 'TABLET' | 'PHONE';
};

type ExportConfig = {
  exportType: 'pdf' | 'csv';
  openShifts: boolean;
  draftShifts: boolean;
  publishedShifts: boolean;
  positions: boolean;
  siteAddress: boolean;
  siteName: boolean;
};

export enum WIDGET {
  ACCOUNT_SETUP = 'account-setup',
  STATISTICS = 'statistics',
  ROSTER_TRENDS_HOURS = 'roster-trends-hours',
  ROSTER_TRENDS_LABOUR_COST = 'roster-trends-labour-cost',
  ROSTER_TRENDS_LABOUR_PERCENTAGE = 'roster-trends-labour-percentage',
  ROSTER_TRENDS_AVG_PAY_RATE = 'roster-trends-avg-pay-rate',
  TIMESHEET_LABOUR_COST = 'timesheet-labour-cost',
  TIMESHEET_HOURS = 'timesheet-hours',
  TIMESHEET_AVG_PAY_RATE = 'timesheet-avg-pay-rate',
  PENDING_SHIFTS = 'pending-shifts',
  SHIFT_SWAPS = 'shift-swaps',
  OPEN_SHIFTS = 'open-shifts',
  LEAVE_REQUESTS = 'leave-requests',
  LIVE_TIME_CLOCK = 'live-time-clock',
  AVAILABILITY = 'availability',
  ANNIVERSARIES = 'anniversaries',
  EXPIRING_DOCUMENTS = 'expiring-documents'
}

export interface DashboardWidget {
  id: WIDGET;
}

export interface DashboardWidgets {
  widgets: DashboardWidget[];
  layout: Layout[];
}

export interface PermissionUserType {
  avatar: string;
  firstName: string;
  id: number;
  lastName: string;
  permission: PERMISSION;
}

export type PushNotificationSettingsType = {
  isLeaveNotificationsOn: Boolean;
  isRosterNotificationsOn: Boolean;
  isShiftNotificationsOn: Boolean;
  isShiftSwapNotificationsOn: Boolean;
};

export enum USER_ROLE_PERMISSION {
  STAFF = 'STAFF',
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER'
}

export interface AccountOptionsType {
  authid: string;
  firstName: string;
  siteId: number;
  siteName: string;
}

export interface UploadPictureResponse {
  imageNames: string[];
  imageUrls: string[];
}
