import clsx from 'clsx';
import { animated } from 'react-spring';
import { useSpring } from '@react-spring/core';
import { Placeholder } from 'react-bootstrap';

import styles from './style.module.scss';
import { Link, LinkProps } from 'react-router-dom';
import { CheckboxMarkedIcon, CloseCircleIcon } from 'components/icons';
import React, { AnchorHTMLAttributes } from 'react';

export interface ResourceLinkBaseProps {
  selected?: boolean;
  showCheckedWhenSelected?: boolean;
  delayFadeIn?: number;
  isLoading?: boolean;
  height?: number;
  minHeight?: number;
  noPadding?: boolean;
  containerClassName?: AnchorHTMLAttributes<HTMLAnchorElement>['className'];
  hasDeleteOnOver?: boolean;
  onClickDelete?: () => void;
}
type Props = ResourceLinkBaseProps & LinkProps;
export default function ResourceLink(props: Props) {
  const {
    showCheckedWhenSelected,
    children,
    height,
    delayFadeIn = 0,
    selected = false,
    isLoading = false,
    style = {},
    className,
    to,
    noPadding,
    minHeight = 40,
    containerClassName,
    hasDeleteOnOver,
    onClickDelete,
    ...rest
  } = props;

  const animation = useSpring({ to: { opacity: 1 }, from: { opacity: 0 }, delay: delayFadeIn });

  if (isLoading) {
    return (
      <Placeholder as="span" animation="glow" className={`${styles.placeholder} d-flex`}>
        <Placeholder xs={12} />
      </Placeholder>
    );
  }

  if (height) style.height = height;
  if (minHeight) style.minHeight = minHeight;

  return (
    <Link to={to} className={`position-relative ${containerClassName ?? ''}`} {...rest}>
      <animated.span
        className={clsx(`${styles.resourceButton} border-1 rounded d-flex align-items-center text-start flex-grow-1 ${className ?? ''}`, {
          [styles.selected]: selected,
          'p-2': !noPadding
        })}
        style={{ ...animation, ...style }}
      >
        {children}
        {selected && showCheckedWhenSelected && (
          <span className={'position-absolute end-0 top-0 text-primary d-flex'}>
            <CheckboxMarkedIcon size={1.3} />
          </span>
        )}

        {hasDeleteOnOver && (
          <span
            className={`deleteHover text-danger position-absolute end-0 me-3`}
            onClick={e => {
              e.preventDefault();
              onClickDelete?.();
            }}
          >
            <CloseCircleIcon />
          </span>
        )}
      </animated.span>
    </Link>
  );
}
