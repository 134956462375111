import { RecordStatus } from '@types';
import { MagnifyIcon } from 'components/icons';
import { Button, ButtonGroup, Form, InputGroup } from 'react-bootstrap';
import styles from './styles.module.scss';

interface Props {
  title: string;
  placeholder?: string;
  currentStatus: RecordStatus;
  setStatus: (status: RecordStatus) => void;
  setSearch: (value: string) => void;
  showActiveInactiveFilter?: boolean;
}

export function SessionHelpBar(props: Props) {
  let { title, currentStatus, setStatus, setSearch, placeholder, showActiveInactiveFilter = true } = props;

  placeholder = placeholder ?? `Search ${title}`;

  return (
    <div className={`d-flex align-items-center gap-3 flex-shrink-0 ${styles.helperBar}`}>
      {showActiveInactiveFilter && (
        <ButtonGroup>
          <Button className="form-control-md" variant={currentStatus === 'ACTIVE' ? 'primary' : 'gray'} onClick={() => setStatus('ACTIVE')}>
            Active
          </Button>
          <Button className="form-control-md" variant={currentStatus === 'INACTIVE' ? 'primary' : 'gray'} onClick={() => setStatus('INACTIVE')}>
            Inactive
          </Button>
        </ButtonGroup>
      )}

      <InputGroup>
        <InputGroup.Text>
          <Form.Label htmlFor={`search-${title}`}>
            <MagnifyIcon size={1.2} />
          </Form.Label>
        </InputGroup.Text>
        <Form.Control
          type={'search'}
          id={`search-${title}`}
          placeholder={placeholder}
          aria-label={title}
          aria-describedby="search"
          onChange={e => setSearch(e.currentTarget.value)}
        />
      </InputGroup>
    </div>
  );
}
