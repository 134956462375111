export function setSessionID(id: string) {
  localStorage.setItem('sid', id);
}

export function getSessionID() {
  return localStorage.getItem('sid');
}

export function clearSessionID() {
  localStorage.clear();
}

export function setAuthIdLoginFlow() {
  localStorage.setItem('authIdLoginFlow', '1');
}

export function getAuthIdLoginFlow() {
  return Boolean(localStorage.getItem('authIdLoginFlow'));
}
