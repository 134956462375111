import { queryClient } from 'api';
import axios from 'axios';
import { defer, LoaderFunctionArgs } from 'react-router-dom';

export function selfOnboardLoader() {
  return async function ({ params }: LoaderFunctionArgs) {
    const userQuery = { queryKey: ['/user'], queryFn: () => axios.get(`/user`) };
    const headerOfficeQuery = { queryKey: ['/sites/head-office'], queryFn: () => axios.get(`/sites/head-office`) };
    const documentsStaffInfoQuery = { queryKey: [`/documents/staff-info/${params.staffId}`], queryFn: () => axios.get(`/documents/staff-info/${params.staffId}`) };
    const documentsStaffQuery = { queryKey: [`/documents/staff/${params.staffId}`], queryFn: () => axios.get(`/documents/staff/${params.staffId}`) };
    const documentTypesQuery = { queryKey: ['/document-types?isActive=1'], queryFn: () => axios.get('/document-types?isActive=1') };

    const user = queryClient.getQueryData(userQuery.queryKey) ?? queryClient.fetchQuery(userQuery);
    const headOffice = queryClient.getQueryData(headerOfficeQuery.queryKey) ?? queryClient.fetchQuery(headerOfficeQuery);
    const documentsStaffInfo = queryClient.getQueryData(documentsStaffInfoQuery.queryKey) ?? queryClient.fetchQuery(documentsStaffInfoQuery);
    const documentsStaff = queryClient.getQueryData(documentsStaffQuery.queryKey) ?? queryClient.fetchQuery(documentsStaffQuery);
    const documentTypes = queryClient.getQueryData(documentTypesQuery.queryKey) ?? queryClient.fetchQuery(documentTypesQuery);

    return defer({ promises: [user, headOffice, documentsStaffInfo, documentsStaff, documentTypes] });
  };
}
