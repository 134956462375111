export type RuleType = {
  finish: number;
  id: number;
  name: string;
  payRuleID: string;
  rate: number;
  ruleType: RULE_TYPE;
  start: number;
  type: string;
  subType: string;
  template: string;
};

export enum RULE_TYPE {
  FIXED_AMOUNT = 'FIXED_AMOUNT',
  EXTRA_HOURS = 'EXTRA_HOURS',
  OVERTIME_WEEKLY = 'OVERTIME-WEEKLY',
  OVERTIME_DAILY = 'OVERTIME-DAILY',
  MIN_ENGAGEMENT = 'MIN_ENGAGEMENT',
  HOLIDAY = 'HOLIDAY',
  ALLOWANCES_DAILY = 'ALLOWANCES-DAILY',
  ALLOWANCES_WEEKLY = 'ALLOWANCES-WEEKLY',
  ALLOWANCES_HOURLY = 'ALLOWANCES-HOURLY',
  ALLOWANCES_MEAL = 'ALLOWANCES-MEAL'
}
