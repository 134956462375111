import { Result, RosterSettingsType } from '@types';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

export const queryFnSettings = (): Promise<Result<RosterSettingsType>> => axios.get('/dashboard/settings/roster-settings');

export function useRosterSettings() {
  const query = useQuery({
    queryKey: ['/dashboard/settings/roster-settings'],
    queryFn: queryFnSettings
  });
  const unpaidBreakTabInfo = query.data?.result.unpaidBreakTabInfo;

  const getBreak = (workedHours: number) => {
    if (unpaidBreakTabInfo) {
      workedHours = Math.min(12, Math.max(1, workedHours));
      const standardBreak = unpaidBreakTabInfo.standardBreak;
      const breakMin = standardBreak[`time${workedHours}`];

      return Number(breakMin);
    }

    return null;
  };

  return { ...query, getBreak };
}
