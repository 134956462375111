import { createContext, ReactNode, useContext, useState } from 'react';
import Loader from 'components/loader/index';

export const GlobalLoaderContext = createContext<any>(false);

export default function GlobalLoader({ children }: { children: ReactNode }) {
  const [show, setShow] = useState(false);

  return (
    <GlobalLoaderContext.Provider value={{ show, setShow }}>
      {children}
      <Loader show={show} fullscreen />
    </GlobalLoaderContext.Provider>
  );
}

export function useGlobalLoader() {
  const { show, setShow } = useContext(GlobalLoaderContext);
  const showGlobalLoader = () => setShow(true);
  const hideGlobalLoader = () => setShow(false);

  return { showGlobalLoader, hideGlobalLoader, isVisible: show };
}
