import React, { lazy, Suspense, useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { FirstLoader } from 'components/loader/first';

const Template = lazy(() => import('components/template'));

export function Protected() {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('authid')) {
      navigate(location.pathname, { replace: true });
    }
  }, [location, navigate, searchParams]);

  return (
    <Suspense fallback={<FirstLoader />}>
      <Template>
        <Outlet />
      </Template>
    </Suspense>
  );
}
