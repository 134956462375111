import { useEffect } from 'react';
import { getEnv } from 'components/allow-user';
import { whichDomain } from 'helpers/which-domain';

export function GTMScript() {
  useEffect(() => {
    let gtmId = '';

    if (whichDomain() === 'rosterelf') {
      gtmId = getEnv() === 'production' ? 'GTM-K6GHS5Q' : 'GTM-WZWJN5P2';
    } else {
      gtmId = getEnv() === 'production' ? 'GTM-TGXJ3ZCV' : 'GTM-PHGH6Q6N';
    }

    if (gtmId.length) {
      let gtmScript = document.getElementById('gtmScript');
      if (!gtmScript) {
        gtmScript = document.createElement('script');
        gtmScript.id = 'gtmScript';
        gtmScript.innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${gtmId}');`;

        const head = document.getElementsByTagName('head')[0];
        head.prepend(gtmScript);

        const noScript = document.createElement('noscript');
        const iframe = document.createElement('iframe');
        iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
        iframe.height = '0';
        iframe.width = '0';
        iframe.className = 'd-none';
        noScript.appendChild(iframe);

        const body = document.getElementsByTagName('body')[0];
        body.prepend(noScript);
      }
    }
  }, []);

  return null;
}
