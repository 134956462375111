import { isAxiosError } from 'axios';

export function getErrorMessage(error: unknown) {
  let body;
  if (isAxiosError(error)) {
    body = error.response?.data.exception || error.response?.data.detail || error.response?.data.error || error.response?.data.result.exception;
    if (!body) body = JSON.stringify(error.response?.data);
  } else if (error instanceof Error) {
    body = error.message;
  } else {
    body = JSON.stringify(error);
  }

  return body as string;
}
