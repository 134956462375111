import { PERMISSION, StaffType, SUBSCRIPTION_NAME, UserInfoType } from '@types';
import { PropsWithChildren } from 'react';

import { useUser } from '../../hooks/api/useUser';

const defaultAllowedPermissions = [PERMISSION.MANAGER, PERMISSION.MANAGER_ASSISTANT, PERMISSION.STAFF];
const defaultDisallowedPermissions = [PERMISSION.STAFF];
const defaultPlans = [SUBSCRIPTION_NAME.PRO, SUBSCRIPTION_NAME.BASIC, SUBSCRIPTION_NAME.FLEX];

interface UserWithPermission {
  permission: StaffType['permission'];
}

interface UserWithPlan {
  subscriptionName?: SUBSCRIPTION_NAME;
}

export function allowUser(
  user?: UserWithPermission & UserWithPlan & Record<string, unknown>,
  allowedPermissions: StaffType['permission'][] = defaultAllowedPermissions,
  allowedPlans: SUBSCRIPTION_NAME[] = defaultPlans,
  test: boolean = false
) {
  if (!user) return false;

  const { permission, subscriptionName } = user;

  return test
    ? [...allowedPermissions].includes(permission) && (subscriptionName ? allowedPlans.includes(subscriptionName) : true)
    : [PERMISSION.ADMIN, ...allowedPermissions].includes(permission) && (subscriptionName ? allowedPlans.includes(subscriptionName) : true);
}

/**
 * Default to not allow PERMISSION.STAFF accessing content.
 * @param user
 * @param disallowedPermissions
 * @param allowedPlans
 */
export function disallowUser(user?: UserInfoType, disallowedPermissions: PERMISSION[] = defaultDisallowedPermissions, allowedPlans: SUBSCRIPTION_NAME[] = defaultPlans) {
  if (!user) return false;
  return !(disallowedPermissions.includes(user.permission) && allowedPlans.includes(user.subscriptionName));
}

interface AllowUserProps {
  allowedPermissions?: PERMISSION[];
  allowedPlans?: SUBSCRIPTION_NAME[];
  test?: boolean;
}

export function AllowUser({ allowedPermissions = defaultAllowedPermissions, allowedPlans = defaultPlans, test, children }: PropsWithChildren<AllowUserProps>) {
  const { data: user } = useUser();
  const allow = allowUser(user?.userInfo, allowedPermissions, allowedPlans, test);

  if (allow) return <>{children}</>;

  return <></>;
}

type Env = 'production' | 'dev' | 'staging' | 'localhost' | 'sandbox' | undefined;
export const getEnv = (): Env => {
  return process.env.REACT_APP_ENV as Env;
};

export const allowEnvs = (envs: Env[]) => envs.includes(getEnv());

interface AllowEnvProps {
  envs: Env[];
}
export function AllowEnvs({ envs, children }: PropsWithChildren<AllowEnvProps>) {
  if (allowEnvs(envs)) return <>{children}</>;
  return <></>;
}
