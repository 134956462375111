import { PlusCircleIcon } from 'components/icons';
import { Link, LinkProps } from 'react-router-dom';

export function AddLink(props: LinkProps) {
  let cn = '';

  if (props?.className) {
    cn = props.className;
  }

  return (
    <Link {...props} className={'add-link text-primary ' + cn}>
      <PlusCircleIcon />
    </Link>
  );
}
