import { SiteType } from '@types';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

export const queryFnAllSites = (): Promise<SiteType[]> => axios.get('/sites/all');

export function useSitesAll() {
  return useQuery<SiteType[]>({
    queryKey: ['/sites/all'],
    queryFn: queryFnAllSites
  });
}
