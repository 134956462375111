import { StaffType } from './staff';
import { ShiftType } from '@types';

type Site = {
  id: number;
  name: string;
  siteName: string;
};

export type EntityType = {
  hasRule: boolean;
  id: number;
  name: string;
  siteName: string;
  sites: Site[];
  token: number;
  type: PAYROLL_FORMAT;
};

type Payroll = {
  from: string;
  hash: string;
  idCompany: number;
  name: string;
  status: PAYROLL_STATUS;
  to: string;
  type: PAYROLL_TYPE;
};

export type PayrollWeekType = {
  company: EntityType;
  payroll: Payroll;
};
export type PayrollWeekResultType = {
  result: {
    currentPage: number;
    totalItens: number;
    weeks: Array<PayrollWeekType[]>;
  };
  status: number;
};

export enum PAYROLL_FORMAT {
  ROSTERELF = 'rosterelf',
  CSV = 'csv',
  XERO = 'xero',
  MYOB = 'myob',
  MICROPAY = 'micropay',
  MICROPAY2 = 'micropay2',
  WAGEEASY = 'wageeasy',
  CUSTOM = 'custom'
}

export enum PAYROLL_CYCLE {
  WEEKLY = 'weekly',
  FORTNIGHTLY = 'fortnightly',
  MONTHLY = 'monthly'
}

export enum PAYROLL_STATUS {
  NEW = 'NEW',
  PUBLISHED = 'PUBLISHED',
  SAVED = 'SAVED'
}

export enum PAYROLL_TYPE {
  CURRENT = 'CURRENT',
  FUTURE = 'FUTURE',
  PAST = 'PAST'
}

export type ReviewRulesType = {
  defaultSelection?: string;
  roundBreak?: string;
  roundBreakFrom?: string;
  roundEnd?: string;
  roundEndFrom?: string;
  roundLimitBreak?: string;
  roundLimitEnd?: string;
  roundLimitStart?: string;
  roundStart?: string;
  roundStartFrom?: string;
};

export type PayrollGroupSite = {
  id: number;
  selectedSiteId: number;
  siteName: string;
};
export type PayrollGroupRuleType = {
  groupId: number;
  groupName: string;
  isActive: boolean;
  isXeroReady?: boolean;
  noDelete: boolean;
};
export type PayrollGroup = {
  companyName: string;
  id: number;
  rules: PayrollGroupRuleType[];
  sites: { [key: string]: PayrollGroupSite };
  token: number | null;
  key: string;
};
export type StaffTemplatesIssueType = {
  result: {
    employees: { [key: string]: EmployeeType };
    idSite: string;
    payrollGroups: { [key: string]: PayrollGroup };
  };
  status: number;
};

export type XeroStaffUnsynced = {
  staff: StaffType;
  synced: [] | XeroStaffType;
};
export type XeroStaffIssueType = {
  result: {
    synced: [] | { [key: string]: XeroStaffUnsynced };
    unsynced: [] | { [key: string]: XeroStaffUnsynced };
  };
};

export type EmployeeType = {
  avatar: string;
  email: string;
  firstName: string;
  id: number;
  lastName: string;
};

export type XeroEmployeeType = {
  staff: StaffType;
  synced: XeroStaffType | [];
};

export type XeroStaffType = {
  email: string;
  employeeId: string;
  firstName: string;
  id: number;
  lastName: string;
  status: 'ACTIVE' | 'INACTIVE';
};

export interface PayrollType {
  rosterMember: Array<ShiftType>;
}
