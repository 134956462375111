import { lazy } from 'react';
import { ModalHeight, ModalSize } from 'components/modal';

import MFASettingsModalStyles from 'components/account/modal/mfa-settings/styles.module.scss';
import RatingModalStyles from 'components/modal/rating/styles.module.scss';
import { PERMISSION } from '@types';

const AddSiteModal = lazy(() => import('components/sites/modal/add'));
const MyProfileModal = lazy(() => import('components/account/modal/my-profile'));
const SelectStaffModal = lazy(() => import('components/modal/select-staff'));
const PermissionsModal = lazy(() => import('components/settings/modal/permissions'));
const RosterSettingsModal = lazy(() => import('components/settings/modal/roster-settings'));
const TimeAndAttendanceModal = lazy(() => import('components/settings/modal/time-attendance'));
const OnboardingSettingsModal = lazy(() => import('components/settings/modal/onboarding'));
const MFASettingsModal = lazy(() => import('components/account/modal/mfa-settings'));
const WidgetManagerModal = lazy(() => import('components/dashboard/modal-widget-manager'));
const RatingModal = lazy(() => import('components/modal/rating'));
const SelectPotentialStaffModal = lazy(() => import('components/modal/select-potential-staff'));
const ShiftSwapApprovalModal = lazy(() => import('components/dashboard/pending-shifts/modal/shift-swap-approval'));
const LeaveRequestModal = lazy(() => import('components/modal/leave-request'));
const AvailabilityInfoModal = lazy(() => import('components/dashboard/availability/modal'));
const DecisionModal = lazy(() => import('components/modal/decision'));
const AccountInfoModal = lazy(() => import('components/account/modal/account-info'));
const SubscriptionModal = lazy(() => import('components/subscription/modal'));
const InfoModal = lazy(() => import('components/modal/info'));
const AddPositionModal = lazy(() => import('components/positions/modal/add'));
const XeroEmployeesSyncModal = lazy(() => import('components/settings/modal/xero-employees-sync'));
const SelectXeroEmployeeModal = lazy(() => import('components/payroll/modal/select-xero-employee'));
const MealAllowanceModal = lazy(() => import('components/payroll/modal/meal-allowance'));
const EmployeesOnLeaveModal = lazy(() => import('components/payroll/modal/employees-on-leave'));
const MissingTemplateModal = lazy(() => import('components/payroll/modal/missing-template'));
const PayrollShiftInfoModal = lazy(() => import('components/payroll/modal/shift-info'));
const LinkStaffXeroModal = lazy(() => import('components/payroll/modal/link-staff-xero'));
const PayrollProcessedModal = lazy(() => import('components/payroll/modal/processed'));
const PayrollReviewAndProcessModal = lazy(() => import('components/payroll/modal/review-and-process'));
const PayrollAddShift = lazy(() => import('components/payroll/modal/add-shift'));
const PayrollXeroWarning = lazy(() => import('components/payroll/modal/process-xero-warning'));
const PrintPayrollModal = lazy(() => import('components/payroll/modal/print-payroll'));
const MyCalendarShiftModal = lazy(() => import('components/my-calendar/modal/calendar-shift'));
const UpgradeToProModal = lazy(() => import('components/modal/upgrade-to-pro'));
const ShiftSwapModal = lazy(() => import('components/my-calendar/modal/shift-swap'));
const MyLeaveModal = lazy(() => import('components/my-calendar/modal/my-leave'));
const AvailabilityModal = lazy(() => import('components/my-calendar/modal/availability'));
const MyCalendarSyncModal = lazy(() => import('components/my-calendar/modal/sync'));
const XeroFailingSubscriptionModal = lazy(() => import('components/modal/xero-failing-subscription'));
const AddStaffModal = lazy(() => import('components/staff/modal/add'));
const SelectSiteModal = lazy(() => import('components/modal/select-site'));
const SelectPositionModal = lazy(() => import('components/modal/select-position'));
const AddLeaveModal = lazy(() => import('components/staff/modal/add-leave'));
const ImportStaffModal = lazy(() => import('components/staff/modal/import-staff'));
const ImportXeroStaffModal = lazy(() => import('components/staff/modal/import-xero-staff'));
const MyAccountantModal = lazy(() => import('components/account/modal/my-accountant'));
const RosterAddShift = lazy(() => import('components/roster/modal/add-shift'));
const RosterAddUnscheduledShift = lazy(() => import('components/add-unscheduled-shift'));
const RosterPublishShift = lazy(() => import('components/roster/modal/publish'));
const RosterCopyARoster = lazy(() => import('components/roster/modal/copy-a-roster'));
const RosterSaveTemplate = lazy(() => import('components/roster/modal/save-template'));
const RosterUploadTemplate = lazy(() => import('components/roster/modal/upload-template'));
const RosterPrint = lazy(() => import('components/roster/modal/print-roster'));
const PayrollAddEntity = lazy(() => import('components/payroll/settings/modal/add-entity'));
const PayrollManageHoliday = lazy(() => import('components/payroll/settings/modal/manage-holiday'));
const ConnectToXero = lazy(() => import('components/payroll/settings/modal/connect-to-xero'));
const PayTemplate = lazy(() => import('components/payroll/settings/modal/pay-template'));
const ClockEventModal = lazy(() => import('components/my-shifts/modal/clock-event'));
const XeroAccountErrorModal = lazy(() => import('components/settings/modal/xero-account-error'));
const CongratulationsModal = lazy(() => import('components/modal/congratulations'));
const DailyRevenueModal = lazy(() => import('components/payroll/modal/daily-revenue'));
const MarkAsSickModal = lazy(() => import('components/modal/mark-as-sick'));
const EditLeaveModal = lazy(() => import('components/modal/edit-leave-request'));
const AppDownloadsModal = lazy(() => import('components/modal/app-downloads'));
const PayrollSettingsModal = lazy(() => import('components/payroll/modal/payroll-settings'));

// This modal is different from RosterAddShift! This one is used on /rosters on mobile.
const EditShiftModal = lazy(() => import('components/rosters/modal/edit-shift'));

/**
 * Register each modal as id: { component: ComponentReference, hasDependencies?: boolean }
 * If hasDependencies = true then the modal cannot be accessed directly.
 */
export type ModalsIdsType =
  | 'missing-template'
  | 'print-payroll'
  | 'link-staff-xero'
  | 'select-xero-employee'
  | 'shift-info'
  | 'info'
  | 'review-and-process'
  | 'payroll-processed'
  | 'add-position'
  | 'add-site'
  | 'roster-settings'
  | 'payroll-settings'
  | 'employees-on-leave'
  | 'my-profile'
  | 'account-info'
  | 'subscription'
  | 'meal-allowance'
  | 'xero-employees-sync'
  | 'permissions'
  | 'decision'
  | 'leave-request'
  | 'availability-info'
  | 'shift-swap-approval'
  | 'select-staff'
  | 'widget-manager'
  | 'onboarding-settings'
  | 'rating'
  | 'mfa-settings'
  | 'time-and-attendance'
  | 'calendar-shift'
  | 'upgrade-to-pro'
  | 'shift-swap'
  | 'my-leave'
  | 'availability'
  | 'calendar-subscription'
  | 'xero-failing-subscription'
  | 'add-staff'
  | 'select-site'
  | 'select-position'
  | 'add-leave'
  | 'import-staff'
  | 'import-xero-staff'
  | 'my-accountant'
  | 'add-shift'
  | 'publish-roster'
  | 'copy-a-roster'
  | 'save-template'
  | 'upload-template'
  | 'print-roster'
  | 'add-payroll-shift'
  | 'process-xero-warning'
  | 'add-entity'
  | 'manage-holiday'
  | 'connect-to-xero'
  | 'pay-template'
  | 'clock-event'
  | 'xero-account-error'
  | 'congratulations'
  | 'daily-revenue'
  | 'edit-shift'
  | 'mark-as-sick'
  | 'edit-leave-request'
  | 'select-potential-staff'
  | 'app-downloads'
  | 'add-unscheduled-shift';

interface ModalDefinition {
  component: any; //change it
  hasDependencies?: boolean;
  size?: ModalSize | 'md';
  height?: ModalHeight;
  contentClassName?: string;
  allowPermissions?: readonly PERMISSION[];
}
const modals: Record<ModalsIdsType, ModalDefinition> = {
  'missing-template': { component: MissingTemplateModal, hasDependencies: true, allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT, PERMISSION.MANAGER] },
  'print-payroll': {
    component: PrintPayrollModal,
    hasDependencies: true,
    size: 'md',
    height: 'h-360',
    allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT, PERMISSION.MANAGER, PERMISSION.MANAGER_ASSISTANT]
  },
  'link-staff-xero': { component: LinkStaffXeroModal, hasDependencies: true, allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT, PERMISSION.MANAGER] },
  'select-xero-employee': {
    component: SelectXeroEmployeeModal,
    hasDependencies: true,
    allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT, PERMISSION.MANAGER],
    height: 'h-540'
  },
  'shift-info': { component: PayrollShiftInfoModal, allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT, PERMISSION.MANAGER, PERMISSION.MANAGER_ASSISTANT] },
  'info': { component: InfoModal, hasDependencies: true },
  'review-and-process': { component: PayrollReviewAndProcessModal, hasDependencies: true, allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT], height: 'h-540' },
  'payroll-processed': { component: PayrollProcessedModal, hasDependencies: true, allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT], size: 'md' },
  'add-position': { component: AddPositionModal, height: 'h-540', allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT, PERMISSION.MANAGER] },
  'add-site': { component: AddSiteModal, height: 'h-540', allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT, PERMISSION.MANAGER] },
  'roster-settings': { component: RosterSettingsModal, height: 'h-540', allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT] },
  'payroll-settings': { component: PayrollSettingsModal, height: 'h-360', size: 'md', allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT] },
  'select-staff': { component: SelectStaffModal, hasDependencies: true, height: 'h-540', allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT, PERMISSION.MANAGER] },
  'select-site': {
    component: SelectSiteModal,
    hasDependencies: true,
    allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT, PERMISSION.MANAGER, PERMISSION.MANAGER_ASSISTANT]
  },
  'select-position': {
    component: SelectPositionModal,
    hasDependencies: true,
    allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT, PERMISSION.MANAGER, PERMISSION.MANAGER_ASSISTANT]
  },
  'employees-on-leave': { component: EmployeesOnLeaveModal, size: 'xl', allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT, PERMISSION.MANAGER] },
  'my-profile': { component: MyProfileModal },
  'account-info': { component: AccountInfoModal, height: 'h-540', allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT] },
  'subscription': { component: SubscriptionModal, allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT] },
  'meal-allowance': { component: MealAllowanceModal, allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT, PERMISSION.MANAGER, PERMISSION.MANAGER_ASSISTANT] },
  'xero-employees-sync': { component: XeroEmployeesSyncModal, allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT], height: 'h-540' },
  'permissions': { component: PermissionsModal, height: 'h-540', allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT] },
  'decision': { component: DecisionModal, hasDependencies: true },
  'leave-request': { component: LeaveRequestModal, size: 'md', allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT, PERMISSION.MANAGER, PERMISSION.MANAGER_ASSISTANT] },
  'edit-leave-request': {
    component: EditLeaveModal,
    size: 'md',
    height: 'h-360',
    allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT, PERMISSION.MANAGER, PERMISSION.MANAGER_ASSISTANT],
    hasDependencies: true
  },
  'shift-swap-approval': {
    component: ShiftSwapApprovalModal,
    size: 'sm',
    allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT, PERMISSION.MANAGER, PERMISSION.MANAGER_ASSISTANT]
  },
  'availability-info': {
    component: AvailabilityInfoModal,
    hasDependencies: true,
    size: 'sm',
    allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT, PERMISSION.MANAGER, PERMISSION.MANAGER_ASSISTANT]
  },
  'widget-manager': { component: WidgetManagerModal, allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT, PERMISSION.MANAGER, PERMISSION.MANAGER_ASSISTANT] },
  'onboarding-settings': { component: OnboardingSettingsModal, size: 'xl', height: 'h-540', allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT] },
  'rating': { component: RatingModal, size: 'md', contentClassName: RatingModalStyles.content, allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT] },
  'mfa-settings': { component: MFASettingsModal, contentClassName: MFASettingsModalStyles.modal, size: 'lg' },
  'time-and-attendance': { component: TimeAndAttendanceModal, size: 'md', height: 'h-540', allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT] },
  'select-potential-staff': {
    component: SelectPotentialStaffModal,
    hasDependencies: true,
    height: 'h-540',
    allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT, PERMISSION.MANAGER, PERMISSION.MANAGER_ASSISTANT]
  },
  'upgrade-to-pro': { component: UpgradeToProModal, size: 'md', allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT] },
  'calendar-shift': { component: MyCalendarShiftModal, size: 'md', height: 'h-360' },
  'shift-swap': { component: ShiftSwapModal, size: 'md' },
  'availability': { component: AvailabilityModal, height: 'h-540' },
  'calendar-subscription': { component: MyCalendarSyncModal },
  'xero-failing-subscription': { component: XeroFailingSubscriptionModal, allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT] },
  'add-staff': { component: AddStaffModal, height: 'h-540', allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT, PERMISSION.MANAGER, PERMISSION.MANAGER_ASSISTANT] },
  'add-leave': { component: AddLeaveModal, size: 'md', height: 'h-360' },
  'my-leave': { component: MyLeaveModal, size: 'md', height: 'h-360' },
  'import-staff': { component: ImportStaffModal, size: 'lg', height: 'h-540', allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT] },
  'import-xero-staff': { component: ImportXeroStaffModal, size: 'lg', height: 'h-540', allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT] },
  'my-accountant': { component: MyAccountantModal, size: 'lg', height: 'h-360', allowPermissions: [PERMISSION.ADMIN] },
  'add-shift': {
    component: RosterAddShift,
    size: 'lg',
    height: 'h-540',
    allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT, PERMISSION.MANAGER, PERMISSION.MANAGER_ASSISTANT]
  },
  'edit-shift': {
    component: EditShiftModal,
    size: 'lg',
    height: 'h-540',
    allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT, PERMISSION.MANAGER, PERMISSION.MANAGER_ASSISTANT]
  },
  'add-payroll-shift': {
    component: PayrollAddShift,
    size: 'lg',
    height: 'h-540',
    allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT, PERMISSION.MANAGER, PERMISSION.MANAGER_ASSISTANT]
  },
  'add-unscheduled-shift': {
    component: RosterAddUnscheduledShift,
    size: 'lg',
    height: 'h-540',
    allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT, PERMISSION.MANAGER, PERMISSION.MANAGER_ASSISTANT, PERMISSION.STAFF]
  },
  'publish-roster': {
    component: RosterPublishShift,
    size: 'lg',
    height: 'h-540',
    allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT, PERMISSION.MANAGER, PERMISSION.MANAGER_ASSISTANT]
  },
  'save-template': { component: RosterSaveTemplate, size: 'sm', allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT, PERMISSION.MANAGER, PERMISSION.MANAGER_ASSISTANT] },
  'upload-template': {
    component: RosterUploadTemplate,
    size: 'md',
    height: 'h-360',
    allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT, PERMISSION.MANAGER, PERMISSION.MANAGER_ASSISTANT]
  },
  'copy-a-roster': {
    component: RosterCopyARoster,
    size: 'md',
    height: 'h-360',
    allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT, PERMISSION.MANAGER, PERMISSION.MANAGER_ASSISTANT]
  },
  'print-roster': {
    component: RosterPrint,
    size: 'md',
    height: 'h-600',
    allowPermissions: [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT, PERMISSION.MANAGER, PERMISSION.MANAGER_ASSISTANT]
  },
  'process-xero-warning': {
    component: PayrollXeroWarning,
    hasDependencies: true,
    size: 'sm'
  },
  'add-entity': {
    component: PayrollAddEntity,
    size: 'sm'
  },
  'manage-holiday': {
    component: PayrollManageHoliday,
    height: 'h-540',
    size: 'md'
  },
  'connect-to-xero': {
    component: ConnectToXero,
    height: 'h-360',
    size: 'sm'
  },
  'pay-template': {
    component: PayTemplate,
    height: 'h-720',
    size: 'lg'
  },
  'clock-event': {
    component: ClockEventModal,
    height: 'h-540',
    size: 'md',
    hasDependencies: true
  },
  'xero-account-error': {
    component: XeroAccountErrorModal,
    height: 'h-360',
    size: 'md',
    hasDependencies: true
  },
  'congratulations': {
    component: CongratulationsModal,
    height: 'h-540',
    size: 'md',
    hasDependencies: true
  },
  'daily-revenue': {
    component: DailyRevenueModal,
    height: 'h-540',
    size: 'sm',
    hasDependencies: true
  },
  'mark-as-sick': {
    component: MarkAsSickModal,
    height: 'h-360',
    size: 'sm'
  },
  'app-downloads': {
    component: AppDownloadsModal,
    height: 'h-540',
    size: 'lg'
  }
} as const;

export { modals };
