import style from './style.module.scss';

/**
 * This loader is being used only once at src/pages/_protected/selection.tsx
 * because the project only starts when the bootstrap has been loaded.
 * Until there we can't use any of their components neither theme colors.
 * @constructor
 */
export function FirstLoader() {
  return <div className={style.loader}>Loading...</div>;
}
