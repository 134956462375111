export const redirectWithoutWww = () => {
  const { hostname, protocol, pathname, search } = window.location;

  // Check if the hostname starts with "www."
  if (hostname.startsWith('www.')) {
    const newHostname = hostname.substring(4); // Remove the "www." prefix
    const newUrl = `${protocol}//${newHostname}${pathname}${search}`;
    window.location.replace(newUrl); // Redirect to the new URL
  }
};
