let appSessionId = getSharedAppSessionId() ?? Date.now().toString();

export function getLocalAppSessionId() {
  return appSessionId;
}

export function setLocalAppSessionId(value: string) {
  appSessionId = value;
}

export function getSharedAppSessionId() {
  return localStorage.getItem('appSessionId');
}

export function setSharedAppSessionId(value: string) {
  localStorage.setItem('appSessionId', value);
}
