import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { clearSessionID } from 'helpers/session-identifier';

export default function Logout() {
  const navigate = useNavigate();
  const qc = useQueryClient();

  useEffect(() => {
    clearSessionID();
    qc.clear();

    navigate('/login', { replace: true });
  }, [navigate, qc]);

  return null;
}
