import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { UserType } from '@types';
import axios from 'axios';

export const queryFnUser = (): Promise<UserType> => axios.get('/user');

export function useUser(options?: Omit<UseQueryOptions<UserType>, 'queryFn' | 'queryKey'>) {
  return useQuery<UserType>({
    queryKey: ['/user'],
    queryFn: queryFnUser,
    ...options
  });
}
