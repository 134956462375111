import dayjs, { Dayjs, PluginFunc, UnitType } from 'dayjs';

export const round: PluginFunc = (option, dayjsClass) => {
  dayjsClass.prototype.round = function (amount, unit) {
    if (amount === 0) return this;

    const mod = this.get(unit as UnitType) % amount;

    if (mod < amount / 2) {
      return this.subtract(mod, unit).startOf(unit);
    }

    return this.add(amount - mod, unit).startOf(unit);
  };
};

export function doDatesOverlap(date1: Dayjs, date2: Dayjs, date3: Dayjs, date4: Dayjs) {
  const start1 = date1.isBefore(date2) ? date1 : date2;
  const end1 = date1.isBefore(date2) ? date2 : date1;

  const start2 = date3.isBefore(date4) ? date3 : date4;
  const end2 = date3.isBefore(date4) ? date4 : date3;

  return start1.isBefore(end2) && start2.isBefore(end1);
}

export function getFormattedAge(age: number): string {
  // Determine the suffix for the age (e.g., "st", "nd", "rd", "th")
  let suffix;
  if (age % 10 === 1 && age !== 11) {
    suffix = 'st';
  } else if (age % 10 === 2 && age !== 12) {
    suffix = 'nd';
  } else if (age % 10 === 3 && age !== 13) {
    suffix = 'rd';
  } else {
    suffix = 'th';
  }

  return `${age}${suffix}`;
}

export const today = dayjs();
export const todayDefaultFormat = today.format('YYYY-MM-DD');
