import { useAppTheme } from 'App';
import { createPortal } from 'react-dom';

import style from './style.module.scss';

type Props = {
  show?: boolean;
  fullscreen?: boolean;
  ratio?: number;
};
export default function Loader({ show = true, fullscreen = false, ratio = 1 }: Props) {
  const theme = useAppTheme();
  const width = 96 * ratio;
  const height = 132 * ratio;

  if (!show || !theme) return null;

  return createPortal(
    <div className={`${fullscreen ? style.fullscreen : 'd-inline-block'}`}>
      <div className={'d-flex flex-column align-items-center'}>
        <div className={`${style.loader} d-flex justify-content-center rounded-circle shadow`} style={{ width: height, height, padding: 20 * ratio }}>
          <img src={require(`images/${theme.folder}_loading.gif`)} width={width} height={height} className={'fit-contain w-100 h-100'} alt={'loader'} />
          {/*  objectFit: contain */}
        </div>
        {fullscreen && <p className={'fs-4 mt-3 text-white fw-bold text-shadow'}>Please wait, we are loading your live data.</p>}
      </div>
    </div>,
    document.body
  );
}
export { useGlobalLoader } from 'components/loader/global';
