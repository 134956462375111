import axios from 'axios';
import { whichDomain } from 'helpers/which-domain';
import { useRouteError } from 'react-router-dom';

type CustomError = {
  message: string;
  statusText: string;
  status: string;
};

export function ErrorComponent() {
  let error = useRouteError();
  let status;
  let statusText;

  if (axios.isAxiosError(error)) {
    statusText = (
      <>
        <span className="text-primary">Oops!</span> {error.response?.statusText}
      </>
    );
    status = <>{error.response?.status}</>;
  } else {
    const errorOutput = error as CustomError;

    if (errorOutput.status) {
      status = <>{errorOutput.status}</>;
      statusText = (
        <>
          <span className="text-primary">Oops!</span> {errorOutput.statusText}
        </>
      );
    } else {
      status = <span className="text-primary">Oops!</span>;
      statusText = <>{errorOutput.message}</>;
    }
  }

  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <div className="text-center px-5 bg-white border-0 rounded">
        <h1 className="display-1 pt-4 fw-bold">{status}</h1>
        <p className="fs-3">{statusText}</p>
        <p className="lead">Please, try again.</p>
        <a href={`https://${whichDomain()}.net/`} className="btn btn-primary fs-4 p-3 m-5">
          go back to {whichDomain()}.net
        </a>
      </div>
    </div>
  );
}
