import { whichDomain } from 'helpers/which-domain';
import { getErrorMessage } from 'helpers/get-error-message';

type RequestErrorProps = {
  error: unknown;
};
export function RequestError(props: RequestErrorProps) {
  const { error } = props;

  const message = getErrorMessage(error);

  return (
    <div className="d-flex align-items-center justify-content-center mt-5 pt-5">
      <div className="d-flex flex-column gap-4 text-center bg-white border-0 rounded p-5">
        <h2 className="fw-bold">An error has occurred</h2>
        <p className="fs-3 fst-italic">{message}</p>
        <p className="lead">Please, try again.</p>
        <a href={`https://${whichDomain()}.net/`} className="btn btn-primary fs-4 p-3">
          go back to {whichDomain()}.net
        </a>
      </div>
    </div>
  );
}
